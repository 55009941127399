<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
    <router-link
      :to="{name: 'users'}"
      class="navbar-brand"
    >
      <img
        src="@/assets/logo.png"
        alt="logo"
      >
    </router-link>
    <button
      class="navbar-toggler ml-auto"
      type="button"
      @click="onToggleCollapse"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      ref="collapse"
    >
      <ul class="navbar-nav ml-auto">
        <li
          class="nav-item"
          v-for="link in navigationLinks"
          :key="link.name"
        >
          <router-link
            :to="{name: link.name}"
            class="nav-link"
            active-class="active"
          >{{link.displayName}}</router-link>
        </li>
        <li class="nav-item">
          <a
            href="#"
            @click="onLogout"
            class="nav-link"
          >Abmelden</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'navbar',
  props: {
    navigationLinks: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onToggleCollapse() {
      this.$refs.collapse.classList.toggle('collapse');
    },
    onLogout() {
      this.$emit('logout');
    }
  }
};
</script>
