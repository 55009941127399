<template>
  <div>
    <navbar @logout="onLogout" :navigationLinks="navigationLinks"></navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar';
import firebase from '../firebase';

export default {
  name: 'index',
  components: {
    Navbar,
  },
  data() {
    return {
      navigationLinks: [],
    };
  },
  async created() {
    try {
      const idTokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult();

      switch (idTokenResult.claims.role) {
        case 'admin': {
          this.navigationLinks = [
            { name: 'availability', displayName: 'Verfügbarkeit' },
            { name: 'calendar', displayName: 'Kalender' },
            { name: 'reservations', displayName: 'Reservierungen' },
            { name: 'bookings', displayName: 'Buchungen' },
            { name: 'weights', displayName: 'Gewichte' },
            { name: 'customers', displayName: 'Kunden' },
            { name: 'teams', displayName: 'Teams' },
            { name: 'users', displayName: 'Benutzer' },
          ];

          break;
        }
        default: {
          this.navigationLinks = [
            { name: 'availability', displayName: 'Verfügbarkeit' },
            { name: 'calendar', displayName: 'Kalender' },
            { name: 'reservations', displayName: 'Reservierungen' },
            { name: 'bookings', displayName: 'Buchungen' },
            { name: 'weights', displayName: 'Gewichte' },
            { name: 'customers', displayName: 'Kunden' },
            { name: 'teams', displayName: 'Teams' },
          ];
        }
      }
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    async onLogout() {
      try {
        await firebase.auth().signOut();

        this.$router.push({ name: 'login' });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>